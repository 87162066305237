<template>
  <div id="brand">
    <template v-if="$route.path === '/videoSafe/brand'">
      <noOpen v-if="status == 2" :list="list"></noOpen>
      <div v-else class="main">
        <div class="allstatus">
          <div v-for="(item, index) in dateList" :key="index">
            <div class="one_status" v-if="item.status == 1">
              <div class="top">
                <div class="top_tu">
                  <img src="../../assets/img/1.3.8/icon_ppbhtb.png" alt="" />
                </div>
                <div class="top_font">
                  视频保护方案已启用，可在直播和录播课章节中可选择该保护方案
                </div>
              </div>
              <div class="bottom">
                <div class="bottom_font1">{{ item.template_name }}</div>
                <div class="bottom_font2">
                  <img src="../../assets/img/1.3.8/icon_ykqi备份.png" alt="" />
                  已启用
                </div>
                <div class="bottom_center">
                  <div class="bottom_center_left" v-if="item.is_watermark == 1">
                    <ul>
                      <li class="flex-center">
                        水印文件：
                        <div style="width: 30px">
                          <img
                            :src="item.watermark_url"
                            alt=""
                            class="shuiiyin"
                          />
                        </div>
                      </li>
                      <li>透明度：{{ item.watermark_opacity }}%</li>
                      <li>
                        出现位置：
                        <span v-if="item.watermark_position == 1">左上角</span>
                        <span v-if="item.watermark_position == 2">右上角</span>
                        <span v-if="item.watermark_position == 3">左下角</span>
                        <span v-if="item.watermark_position == 4">右下角</span>
                        <span v-if="item.watermark_position == 5">
                          居中画面
                        </span>
                      </li>
                      <li>水印大小：{{ item.watermark_size }}%</li>
                    </ul>
                  </div>
                  <div
                    class="divider"
                    style="width: 1px"
                    v-if="item.is_watermark == 1 && item.is_copyright == 1"
                  ></div>
                  <div
                    class="bottom_center_rigth"
                    v-if="item.is_copyright == 1"
                  >
                    <ul>
                      <li
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 7;
                          -webkit-box-orient: vertical;
                          word-break: break-all;
                        "
                      >
                        版权声明:{{ item.copyright_content }}
                      </li>
                      <li>
                        出现位置：

                        <span v-if="item.copyright_position == 1">上方</span>
                        <span v-if="item.copyright_position == 2">中间</span>
                        <span v-if="item.copyright_position == 3">下方</span>
                      </li>
                      <!-- <li>出现频率：一直出现</li> -->
                      <li>
                        轮播速度：
                        <span v-if="item.copyright_speed == 1">高速</span>
                        <span v-if="item.copyright_speed == 2">中速</span>
                        <span v-if="item.copyright_speed == 3">低速</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bottoms">
                  <el-button
                    v-if="showDeactivateBtn"
                    size="small"
                    type="warning"
                    @click="stopUse(item.template_id)"
                  >
                    停用
                  </el-button>
                  <el-button
                    v-if="showEditBtn"
                    size="small"
                    type="primary"
                    @click="edit(item.template_id)"
                  >
                    编辑
                  </el-button>
                  <!-- <el-button size="small" type="primary">查看效果</el-button> -->
                  <el-button
                    v-if="showDeleteBtn"
                    size="small"
                    type="danger"
                    @click="delUse(item.template_id)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </div>
            <div class="two_status" v-if="item.status == 2">
              <div class="top">
                <div class="top_tu">
                  <img src="../../assets/img/1.3.8/icon_wkqppbhtb.png" alt="" />
                </div>
                <div class="top_font">视频保护方案未启用</div>
              </div>

              <div class="bottom">
                <div class="bottom_font1">{{ item.template_name }}</div>
                <div class="bottom_font2" style="color: #999999">
                  <img src="../../assets/img/1.3.8/icon_wqy.png" alt="" />
                  未启用
                </div>

                <div class="bottom_center">
                  <div class="bottom_center_left" v-if="item.is_watermark == 1">
                    <ul>
                      <li class="flex-center">
                        水印文件：
                        <div style="width: 30px">
                          <img
                            :src="item.watermark_url"
                            alt=""
                            class="shuiiyin"
                          />
                        </div>
                      </li>
                      <li>透明度：{{ item.watermark_opacity }}%</li>
                      <li>
                        出现位置：
                        <span v-if="item.watermark_position == 1">左上角</span>
                        <span v-if="item.watermark_position == 2">右上角</span>
                        <span v-if="item.watermark_position == 3">左下角</span>
                        <span v-if="item.watermark_position == 4">右下角</span>
                        <span v-if="item.watermark_position == 5">
                          居中画面
                        </span>
                      </li>
                      <li>水印大小：{{ item.watermark_size }}%</li>
                      55555555555
                    </ul>
                  </div>
                  <div
                    class="divider"
                    style="width: 1px"
                    v-if="item.is_watermark == 1 && item.is_copyright == 1"
                  ></div>
                  <div
                    class="bottom_center_rigth"
                    v-if="item.is_copyright == 1"
                  >
                    <ul>
                      <li
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 7;
                          -webkit-box-orient: vertical;
                          word-break: break-all;
                        "
                      >
                        版权声明:{{ item.copyright_content }}
                      </li>
                      <li>
                        出现位置：

                        <span v-if="item.copyright_position == 1">上方</span>
                        <span v-if="item.copyright_position == 2">中间</span>
                        <span v-if="item.copyright_position == 3">下方</span>
                      </li>
                      <!-- <li>出现频率：一直出现</li> -->
                      <li>
                        轮播速度：
                        <span v-if="item.copyright_speed == 1">高速</span>
                        <span v-if="item.copyright_speed == 2">中速</span>
                        <span v-if="item.copyright_speed == 3">低速</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bottoms">
                  <el-button
                    size="small"
                    type="primary"
                    @click="openUse(item.template_id)"
                  >
                    启用
                  </el-button>
                  <el-button
                    v-if="showEditBtn"
                    size="small"
                    type="primary"
                    @click="edit(item.template_id)"
                  >
                    编辑
                  </el-button>
                  <!-- <el-button size="small" type="primary">查看效果</el-button> -->
                  <el-button
                    v-if="showDeleteBtn"
                    size="small"
                    type="danger"
                    @click="delUse(item.template_id)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showCreateBtn" class="newprotect cp" @click="newly">
            <div class="newprotect_new">
              <div>
                <img src="../../assets/img/1.3.8/icon_tj备份.png" alt="" />
              </div>
              <div class="newprotect_font1">新建自定义保护方案</div>
              <div class="newprotect_font2">10项内容可自行定制</div>
            </div>
          </div>
        </div>
      </div>
      <copyrightprotect></copyrightprotect>
    </template>
    <router-view />
  </div>
</template>

<script>
import noOpen from '@/components/copyrightprotect/noOpen' //顶部开通
import copyrightprotect from '@/components/copyrightprotect/index' //轮播图
export default {
  name: 'brand',
  data() {
    return {
      dateList: [],
      status: '',
      list: {
        status: 1,
        src: require('@/assets/img/1.3.8/icon_bqbhtb.png'),
        title: '数字版权保护系统',
        describe:
          '三项专利技术加持，集合视频保护、品牌保护、账号保护等3项技术…%追踪翻录视频的账号',
        button_name: '申请试用',
        money: 1999,
        strike: 3999,
        function_tu: require('@/assets/img/1.3.8/img_bqjs.jpg'),
        tip: '/年',
      },
    }
  },
  watch: {
    $route(to) {
      if (to.path === '/videoSafe/brand') {
        this.getDate()
      }
    },
  },

  computed: {
    showCreateBtn() {
      return this.$store.state.roots.includes(100)
    },

    showEditBtn() {
      return this.$store.state.roots.includes(101)
    },

    showDeleteBtn() {
      return this.$store.state.roots.includes(102)
    },

    showDeactivateBtn() {
      return this.$store.state.roots.includes(103)
    },
  },

  components: {
    copyrightprotect,
    noOpen,
  },
  created() {
    this.status = this.$store.state.userInfo.jg_is_drm
    if (this.status == 1) {
      this.getDate()
    }
  },
  methods: {
    edit(val) {
      this.$router.push({
        path: '/videoSafe/brand/newly2',
        query: { name: '编辑', status: 2, template_id: val },
      })
    },
    newly() {
      // if (this.dateList.length >= 10) {
      //   this.$root.prompt('最多可自行定制10项内容')
      //   return
      // }
      this.$router.push({
        path: '/videoSafe/brand/newly2',
        query: { name: '新建', status: 1 },
      })
    },
    delUse(val) {
      this.$confirm('是否删除该方案？确定后无法恢复！', '删除方案', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http({
            url: '/BrandSafeTemplate/closeBrandSafe',
            data: {
              template_id: val,
              type: 2,
            },
            callback: ({ code }) => {
              if (code == 200) {
                this.getDate()
              }
            },
            error: ({ code }) => {
              if (code == 403) {
                this.$alert(
                  '该保护方案已关联有课程章节，无法删除！如需删除，请在章节管理中取消包含方案',
                  '温馨提示',
                  {
                    confirmButtonText: '知道了',
                    callback: () => {},
                  }
                )
              }
            },
          })
        })
        .catch(() => {})
    },
    openUse(val) {
      this.$http({
        url: '/BrandSafeTemplate/closeBrandSafe',
        data: {
          template_id: val,
          status: 1,
          type: 1,
        },
        callback: () => {
          this.getDate()
        },
      })
    },
    stopUse(val) {
      this.$confirm(
        '是否停用该方案？停用后，已转码加密的视频依然生效，跑马灯和终端限制功能失效',
        '停用方案',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/BrandSafeTemplate/closeBrandSafe',
          data: {
            template_id: val,
            status: 2,
            type: 1,
          },
          callback: () => {
            this.getDate()
          },
        })
      })
    },
    getDate() {
      this.$http({
        url: '/BrandSafeTemplate/getList',
        // data: {
        //   order_id: this.data_id
        // },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
          }
        },
        error: (error) => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#brand {
  .main {
    margin-top: 20px;
    background-color: #fff;
    padding: 0 0px 29px 40px;
    .shuiiyin {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    .allstatus {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
    }
    .one_status,
    .two_status,
    .newprotect {
      margin-top: 29px;
      margin-right: 25px;
      width: 404px;
      height: 470px;
      background: #f5f5f5;
      box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      .top {
        height: 136px;
        background: #ff7635;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        align-items: center;
        .top_tu {
          margin: 33px 20px;
        }
        .top_font {
          font-size: 14px;
          color: #ffffff;
          line-height: 24px;
          margin-right: 20px;
        }
      }
      .bottom {
        .bottom_font1 {
          margin: 14px 20px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 16px;
        }
        .bottom_font2 {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #18c062;
          line-height: 12px;
          margin-left: 20px;
          margin-bottom: 29px;
          img {
            margin-right: 10px;
          }
        }
        .bottom_center {
          height: 175px;
          font-size: 12px;
          color: #333333;
          line-height: 17px;
          margin: 0 20px 26px;
          display: flex;
          .bottom_center_left {
            ul {
              width: 177px;
              li {
                margin-bottom: 11px;
              }
            }
          }
          .divider {
            height: 100%;
            min-width: 1px;
            background: #e8e8e8;
          }
          .bottom_center_rigth {
            width: 166px;
            ul {
              margin-left: 20px;
              li {
                margin-bottom: 11px;
              }
            }
          }
        }
        .bottoms {
          // margin-left: 27px;
          width: 261px;
          margin: 0 auto;
          ::v-deep .el-button {
            width: 80px;
            height: 30px;
            line-height: 12px;
          }
        }
      }
    }
    .newprotect {
      .newprotect_new {
        width: 180px;
        margin: 0 auto;
        margin-top: 182px;
        img {
          margin: 0 auto;
          cursor: pointer;
        }
        .newprotect_font1 {
          font-size: 20px;
          color: #333333;
          line-height: 20px;
          margin-top: 20px;
        }
        .newprotect_font2 {
          text-align: center;
          font-size: 12px;
          color: #1b9d97;
          line-height: 12px;
          margin: 0 auto;
          margin-top: 10px;
        }
      }
    }
    .two_status {
      .top {
        background-color: #989898;
      }
    }
  }
  // .dialogVisible {
  //   ::v-deep .el-dialog {
  //     height: 416px;
  //   }
  //   ::v-deep .el-dialog__title {
  //     font-size: 14px;
  //     color: #333333;
  //     line-height: 19px;
  //   }
  //   ::v-deep .el-dialog__header {
  //     padding-top: 14px;
  //   }
  //   .dialogVisible_bottom {
  //     width: 168px;
  //     margin: 0 auto;
  //     .dialogVisible_bottom_font1 {
  //       margin-top: 40px;
  //       font-size: 16px;
  //       color: #4a4a4a;
  //       line-height: 21px;
  //       margin-bottom: 65px;
  //     }
  //     img {
  //       margin-left: 9px;
  //     }
  //   }
  // }
}
</style>
